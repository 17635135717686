import React from 'react';
import ReservationForm from './ReservationForm';
import logo from "./logo.png";

function HomePage() {
    return (
        <div>
            <header className="App-header">
                <img src={logo} alt="Restaurant Logo" />
            </header>
            <ReservationForm />
        </div>
    );
}

export default HomePage;
