import React, { useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Header from "./Header";
import './ConfirmationPage.css';  // Ensure the CSS is imported
import confetti from 'canvas-confetti';
import sessionTimeout from "./sessionTimeout";

function ConfirmationPage() {
    const location = useLocation();
    const { details } = location.state;
    const navigate = useNavigate();

    useEffect(() => {
        // Trigger confetti on mount
        confetti({
            particleCount: 150,
            spread: 70,
            origin: { y: 0.6 }
        });

        // Set a timeout to redirect the user after 10 minutes
        sessionTimeout(600000, '/');

    }, [navigate]);

    return (
        <div className="container">
            <Header />
            <h1 className="confirmation-title">Reservation Confirmation</h1>
            <div className="confirmation-details">
                <p className="detail-item"><strong>Name:</strong> {details.fullName}</p>
                <p className="detail-item"><strong>Email:</strong> {details.email}</p>
                <p className="detail-item"><strong>Number of Guests:</strong> {details.numberOfGuests}</p>
                <p className="detail-item"><strong>Phone:</strong> {details.phone}</p>
                <p className="detail-item"><strong>Date:</strong> {details.date}</p>
                <p className="detail-item"><strong>Time:</strong> {details.time}</p>
                <p className="confirmation-email-note">A confirmation will be sent to your email shortly. Thank you.</p>
            </div>
        </div>
    );
}

export default ConfirmationPage;
