import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import ConfirmationPage from './components/ConfirmationPage';
import ModifyReservationPage from './components/ModifyReservationPage';
import CancelReservationPage from './components/CancelReservationPage';
import ModifiedConfirm from './components/ModifiedConfirm';
import CancelConfirmPage from './components/CancelConfirmPage';
import NotFoundPage from './components/NotFoundPage';
import QRConfirm from './components/QRConfirm';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/confirmation" element={<ConfirmationPage />} />
          <Route path="/modify-reservation/:secureToken/" element={<ModifyReservationPage />} />
          <Route path="/cancel-reservation/:secureToken/" element={<CancelReservationPage />} />
          <Route path="/modified-confirm" element={<ModifiedConfirm />} />
          <Route path="/cancel-confirm" element={<CancelConfirmPage />} />
          <Route path="/not-found" element={<NotFoundPage />} />
          <Route path="/confirm-reservation/:secureToken/" element={<QRConfirm />} />
          {/* Future routes can go here */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
