import React from "react";
import { useParams, useNavigate } from 'react-router-dom';
import Header from "./Header";

function CancelReservationPage() {
    const { secureToken } = useParams();
    const navigate = useNavigate();

    const handleCancel = async () => {
        if (window.confirm("Are you sure you want to cancel this reservation?")) {
            try {
                const response = await fetch(`https://kingkaewreservation.com/api/cancel-reservation/${secureToken}/`, {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': 'FETCHED_TOKEN'
                    }
                });
                if (response.ok) {
                    navigate('/cancel-confirm'); // Redirect to the confirmation page
                } else if (response.status === 404) {
                    navigate('/not-found');
                } else {
                    throw new Error('Failed to cancel reservation');
                }
            } catch (error) {
                alert('Failed to fetch: ' + error.message);
            }
        }
    };

    return (
        <div>
            <Header />
            <h1>Are you sure you want to cancel this reservation?</h1>
            <button onClick={handleCancel}>Confirm Cancellation</button>
        </div>
    );
}

export default CancelReservationPage;