import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from './Header';

function ModifyReservationPage() {
    const { secureToken } = useParams();
    const navigate = useNavigate();
    const [reservation, setReservation] = useState({
        date: '',
        time: ''
    });
    const [loading, setLoading] = useState(false);
    const [minDate, setMinDate] = useState('');
    const [dateError, setDateError] = useState('');
    const dateRef = useRef(null);

    useEffect(() => {
        // Get current time in Thailand
        const now = new Date();
        const thailandTime = new Date(now.toLocaleString("en-US", { timeZone: "Asia/Bangkok" }));
        const currentHour = thailandTime.getHours();
        thailandTime.setHours(0, 0, 0, 0);

        const todayFormatted = thailandTime.toISOString().split('T')[0];

        // Calculate tomorrow's date
        thailandTime.setDate(thailandTime.getDate() + 1);
        const formattedTomorrow = thailandTime.toISOString().split('T')[0];

        // Set minDate based on current Thailand time
        setMinDate(currentHour < 12 ? todayFormatted : formattedTomorrow);

        // Fetch the current reservation details
        fetch(`https://kingkaewreservation.com/api/modify-reservation/${secureToken}/`)
            .then(res => {
                if (!res.ok) {
                    throw new Error('Reservation not found');
                }
                return res.json();
            })
            .then(data => {
                if (data) {
                    setReservation({
                        date: data.date,
                        time: data.time
                    });
                } else {
                    throw new Error('Reservation not found');
                }
            })
            .catch(() => {
                navigate('/not-found');
            });
    }, [secureToken, navigate]);

    const handleDateChange = (e) => {
        const newDate = e.target.value;
        const dateObject = new Date(newDate + "T00:00:00"); // Ensures correct date parsing in Thailand time
        const now = new Date();
        const thailandTime = new Date(now.toLocaleString("en-US", { timeZone: "Asia/Bangkok" }));

        const thailandToday = new Date(thailandTime.getFullYear(), thailandTime.getMonth(), thailandTime.getDate());
        const currentHour = thailandTime.getHours(); // Get the current hour in Thailand

        if (dateObject.toISOString().split('T')[0] === thailandToday.toISOString().split('T')[0] && currentHour >= 12) {
            alert("The same day reservation is cut-off at noon. Please select a later date.");
            return; // Prevent the date change if it's past the cut-off time
        }

        // Check if the selected date is in the past relative to the minimum date
        if (dateObject < thailandToday) {
            alert("Although time is relative, you cannot select a past date :(");
            return;
        }

        // Check for Wednesday closure
        if (dateObject.getDay() === 3) {
            setDateError("We're closed on Wednesday. Please select another day.");
        } else {
            setDateError('');
        }

        setReservation(prev => ({
            ...prev,
            date: newDate
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        // Check if the selected date is a Wednesday
        const selectedDate = new Date(reservation.date + "T00:00:00");
        if (selectedDate.getDay() === 3) {
            setDateError("We're closed on Wednesday. Please select another day.");
            scrollToField(dateRef);
            return;
        }

        setLoading(true); // Start loading

        fetch(`https://kingkaewreservation.com/api/modify-reservation/${secureToken}/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(reservation)
        })
            .then(response => {
                if (response.ok) return response.json();
                throw new Error('Failed to modify reservation');
            })
            .then(data => {
                alert('Reservation modified successfully');
                navigate('/modified-confirm', { state: { reservation: data } });
            })
            .catch(err => setDateError(err.message))
            .finally(() => {
                setLoading(false); // Stop loading
            });
    };

    const scrollToField = (ref) => {
        ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        window.scrollBy(0, -200);
    };

    const timeSlots = ['15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00'];

    const handleTimeSelect = (time) => {
        setReservation(prev => ({ ...prev, time }));
    };

    // Determine if the selected date is a weekday or weekend
    const isWeekday = () => {
        const date = new Date(reservation.date + 'T00:00:00');
        const day = date.getDay();
        return day !== 0 && day !== 6;
    };

    return (
        <div>
            <Header />
            <h1>Modify Reservation</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    Date:
                    <input type="date" name="date" value={reservation.date} onChange={handleDateChange} required ref={dateRef} min={minDate} />
                    {dateError && <div style={{ color: 'red' }}>{dateError}</div>}
                </label>
                <label>
                    Time:
                    <div>
                        {timeSlots.map((time, index) => (
                            <button
                                key={index}
                                type="button"
                                className={`time-button ${reservation.time === time ? 'selected' : ''} ${isWeekday() && time === '15:00' ? 'disabled' : ''}`}
                                onClick={() => { if (!(isWeekday() && time === '15:00')) handleTimeSelect(time); }}
                                disabled={isWeekday() && time === '15:00'}
                            >
                                {time}
                            </button>
                        ))}
                    </div>
                </label>
                <button type="submit">Update Reservation</button>
                {loading && (
                    <div className="loading-spinner">
                        <dotlottie-player
                            src="https://lottie.host/7e326d25-ceba-45e4-996a-ecf989492080/EljaBZWVXc.json"
                            background="transparent"
                            speed="1"
                            style={{ width: 300, height: 300 }}
                            direction="1"
                            playMode="normal"
                            loop
                            autoplay
                        ></dotlottie-player>
                    </div>
                )}
            </form>
        </div>
    );
}

export default ModifyReservationPage;
