import React from "react";
import { Link } from 'react-router-dom';
import Header from "./Header";
import './CancelConfirmPage.css';

function CancelConfirmPage() {
    return (
        <div className="cancel-confirm-container">
            <Header />
            <div className="cancel-message-container">
                <h1 className="cancel-title">Reservation Canceled</h1>
                <p className="cancel-message">Your reservation has been successfully canceled.</p>
                <Link to="/" className="new-reservation-link">Make a new reservation</Link>
            </div>
        </div>
    );
}

export default CancelConfirmPage;