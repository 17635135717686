import React, { useState, useEffect } from "react";
import './NotFoundPage.css';
import Header from "./Header";

function NotFoundPage() {
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setShowMessage(true), 2000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="not-found-container">
            <Header />
            {!showMessage ? (
                <div className="animation-container">
                    <dotlottie-player
                        src="https://lottie.host/040956a6-89a5-4f71-8ab2-c04ced43bf5f/lS5i9ODXN6.json"
                        background="transparent"
                        speed="1"
                        style={{ width: '300px', height: '300px' }}
                        direction="1"
                        playMode="normal"
                        autoplay
                    ></dotlottie-player>
                </div>
            ) : (
                <div className="message-container">
                    <h1 className="not-found-title">Reservation Not Found</h1>
                    <p className="not-found-message">
                        The reservation you are trying to access does not exist or has been canceled.
                    </p>
                </div>
            )}
        </div>
    );
}

export default NotFoundPage;