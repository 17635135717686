import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from './Header';
import './QRConfirm.css';

function QRConfirm() {
    const { secureToken } = useParams();
    const navigate = useNavigate();
    const [reservation, setReservation] = useState(null);
    const [error, setError] = useState('');
    const [showDetails, setShowDetails] = useState(false);

    useEffect(() => {
        fetch(`https://kingkaewreservation.com/api/confirm-reservation/${secureToken}/`)
            .then(response => {
                if (!response.ok) throw new Error('Reservation not found');
                return response.json();
            })
            .then(data => setReservation(data))
            .catch(error => {
                setError(error.message);
                navigate('/not-found'); // Redirect to not found page if reservation doesn't exist
            });
    }, [navigate, secureToken]);

    useEffect(() => {
        if (reservation) {
            const timer = setTimeout(() => setShowDetails(true), 2000); // 3 seconds for animation
            return () => clearTimeout(timer);
        }
    }, [reservation]);

    if (error) return <div className="error-message">Error: {error}</div>;
    if (!reservation) return <div className="loading-message">Loading...</div>;

    return (
        <div className="qr-confirm-container">
            <Header />
            {!showDetails ? (
                <div className="animation-container">
                    <dotlottie-player
                        src="https://lottie.host/4f932024-3583-4aa2-8b49-bdda821402cb/g3g3x4yUH9.json"
                        background="transparent"
                        speed="1"
                        style={{ width: '300px', height: '300px' }}
                        direction="1"
                        playMode="normal"
                        autoplay
                    ></dotlottie-player>
                </div>
            ) : (
                <div className="details-container">
                    <h1 className="confirm-title">Reservation Confirmation</h1>
                    <div className="reservation-details">
                        <p><span className="detail-label">Name:</span> {reservation.fullName}</p>
                        <p><span className="detail-label">Email:</span> {reservation.email}</p>
                        <p><span className="detail-label">Date:</span> {reservation.date}</p>
                        <p><span className="detail-label">Guests #:</span> {reservation.numberOfGuests}</p>
                        <p><span className="detail-label">Time:</span> {reservation.time}</p>
                        <p><span className="detail-label">Phone:</span> {reservation.phone}</p>
                    </div>
                </div>
            )}
        </div>
    );
}

export default QRConfirm;