import React from "react";
import { useLocation } from "react-router-dom";
import "./ModifiedConfirm.css";
import Header from "./Header";

function ModifiedConfirm() {
    const location = useLocation();
    const reservation = location.state?.reservation;

    if (!reservation) {
        return <p className="error-message">No modified reservation data found.</p>;
    }

    return (
        <div className="confirm-container">
            <Header />
            <h1 className="confirm-title">Modified Reservation Confirmation</h1>
            <div className="reservation-details">
                <p><span className="detail-label">Name:</span> {reservation.fullName}</p>
                <p><span className="detail-label">Email:</span> {reservation.email}</p>
                <p><span className="detail-label">Phone:</span> {reservation.phone}</p>
                <p><span className="detail-label">Date:</span> {reservation.date}</p>
                <p><span className="detail-label">Time:</span> {reservation.time}</p>
                <p className="status-message">Status: Modified Successfully</p>
            </div>
        </div>
    );
}

export default ModifiedConfirm;