import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function ReservationForm() {
    const [reservation, setReservation] = useState({
        fullName: '',
        email: '',
        phone: '',
        numberOfGuests: '',
        date: '', // Will be set to Thailand's current date initially
        time: ''
    });

    const [error, setError] = useState({
        fullName: '',
        email: ''
    });
    const [reservationLinks, setReservationLinks] = useState({});
    const emailRef = useRef(null); // Create a ref for the email input field
    const [minDate, setMinDate] = useState('');
    const navigate = useNavigate();
    const [dateError, setDateError] = useState('');
    const dateRef = useRef(null);
    const [loading, setLoading] = useState(false); // Loading State

    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    const csrftoken = getCookie('csrftoken');

    useEffect(() => {
        // Get current time in Thailand
        const now = new Date();
        const thailandTime = new Date(now.toLocaleString("en-US", { timeZone: "Asia/Bangkok" }));
        const currentHour = thailandTime.getHours(); // Thailand local hour
        thailandTime.setHours(0, 0, 0, 0); // Start of today for comparison

        const todayFormatted = thailandTime.toISOString().split('T')[0];

        // Calculate tomorrow's date
        thailandTime.setDate(thailandTime.getDate() + 1);
        const formattedTomorrow = thailandTime.toISOString().split('T')[0];

        const validStartDate = currentHour >= 12 ? formattedTomorrow : todayFormatted;

        // Set minDate based on current Thailand time
        setReservation(prev => ({ ...prev, date: validStartDate }));
        setMinDate(currentHour < 12 ? todayFormatted : formattedTomorrow);
    }, []);

    const checkExistingReservation = async () => {
        if (!reservation.email) {
            setError(prev => ({ ...prev, email: "Email is required" }));
            return false;
        }
        try {
            const response = await fetch(`https://kingkaewreservation.com/api/check-email-existence/?email=${encodeURIComponent(reservation.email)}`);
            const data = await response.json();
            if (data.exists) {
                setError(prev => ({ ...prev, email: "A reservation with this email already exists." }));
                setReservationLinks({
                    modifyLink: data.modify_url,
                    cancelLink: data.cancel_url
                });
                scrollToField(emailRef);
                return false;
            }
            return true;
        } catch (error) {
            console.error("Error checking existing reservation:", error);
            setError(prev => ({ ...prev, email: "Failed to check reservation existence." }));
            return false;
        }
    };

    //    const verifyEmail = async () => {
    //        if (!reservation.email) {
    //            setError(prev => ({ ...prev, email: "Email is required." }));
    //            scrollToField(emailRef);
    //            return false;
    //        }
    //        try {
    //            const response = await fetch(`http://localhost:8000/api/verify-email/?email=${encodeURIComponent(reservation.email)}`);
    //            const data = await response.json();
    //            if (data.result === 'undeliverable') {
    //                setError(prev => ({ ...prev, email: "This email is not valid, please use a validated email." }));
    //                scrollToField(emailRef);
    //                return false;
    //            }
    //           return true;
    //        } catch (error) {
    //            console.error("Failed to verify email:", error);
    //            setError(prev => ({ ...prev, email: "Failed to verify email. " + error.toString() }));
    //            scrollToField(emailRef);
    //            return false;
    //        }
    //    };

    const scrollToField = (ref) => {
        ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        window.scrollBy(0, -200); // Adjust this value based on your form's layout
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'fullName') {
            if (/[!@#$%^&*();:'"[\]{}<>/\\|+=_-]/.test(value)) {
                setError(prev => ({ ...prev, fullName: "Special characters are not allowed in the name." }));
                return; // Stop updating state if invalid input is detected
            } else {
                setError(prev => ({ ...prev, fullName: '' })); // Clear the error if input is valid
            }
        }

        // Validate email format
        if (name === 'email') {
            const emailRegex = /^[\w.-]+@[\w.-]+\.\w+$/;
            if (!emailRegex.test(value)) {
                setError(prev => ({ ...prev, email: "Please enter a valid email address." }));
            } else {
                setError(prev => ({ ...prev, email: '' }));
            }
        }

        // Updated to handle all inputs including date and time without additional logic
        setReservation(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleDateChange = (e) => {
        const newDate = e.target.value;
        const dateObject = new Date(newDate + "T00:00:00"); // Ensures correct date parsing in Thailand time
        const now = new Date();
        const thailandTime = new Date(now.toLocaleString("en-US", { timeZone: "Asia/Bangkok" }));

        const thailandToday = new Date(thailandTime.getFullYear(), thailandTime.getMonth(), thailandTime.getDate());
        const currentHour = thailandTime.getHours(); // Get the current hour in Thailand

        if (dateObject.toISOString().split('T')[0] === thailandToday.toISOString().split('T')[0] && currentHour >= 12) {
            alert("The same day reservation is cut-off at noon. Please select a later date.");
            return; // Prevent the date change if it's past the cut-off time
        }

        // Check if the selected date is in the past relative to the minimum date
        if (dateObject < thailandToday) {
            alert("Although time is relative, you cannot select a past date :(");
            return;
        }

        // Check for Wednesday closure
        if (dateObject.getDay() === 3) {
            setDateError("We're closed on Wednesday. Please select another day.");
        } else {
            setDateError('');
        }

        setReservation(prev => ({
            ...prev,
            date: newDate
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if the selected date is a Wednesday
        const selectedDate = new Date(reservation.date + "T00:00:00");
        if (selectedDate.getDay() === 3) {
            setDateError("We're closed on Wednesday. Please select another day.");
            scrollToField(dateRef);
            return;
        }

        // check an email if the reservation is exist
        const reservationExists = await checkExistingReservation();
        if (!reservationExists) {
            // If the reservation exists, stop further execution and alert the user

            return;
        }
        // verify the email is valid before asking for confirmation
        //        const emailIsValid = await verifyEmail();
        //        if (!emailIsValid) {
        // If the email is not valid, stop further execution
        //            return;
        //        }

        // Confirm dialog to ask user to confirm the reservation
        const userConfirmed = window.confirm("Confirm Reservation?");
        if (!userConfirmed) return;

        setLoading(true); // Start Loading

        try {
            const response = await fetch('https://kingkaewreservation.com/api/reservations/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrftoken,
                },
                body: JSON.stringify(reservation)
            });

            if (!response.ok) throw new Error('Failed to submit reservation.');
            navigate('/confirmation', { state: { details: reservation } });
        } catch (error) {
            setError({ form: error.message });
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const timeSlots = ['15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00'];

    const handleTimeSelect = (time) => {
        setReservation(prev => ({ ...prev, time }));
    };

    // Determine if the selected date is a weekday or weekend
    const isWeekday = () => {
        const date = new Date(reservation.date + 'T00:00:00');
        const day = date.getDay();
        return day !== 0 && day !== 6;
    };

    return (
        <div className="form-container">
            <h2>Reserve Table</h2>
            <p>Please fill the form below accurately to enable us to serve you better!.. Thank you!</p>
            <form onSubmit={handleSubmit} className="reservation-form">
                <div className="form-row">
                    <label htmlFor="fullName">Name:</label>
                    <input id="fullName" name="fullName" type="text" onChange={handleChange} value={reservation.fullName} required />
                    {error.fullName && <div style={{ color: 'red' }}>{error.fullName}</div>}
                </div>
                <div className="form-row">
                    <label htmlFor="email">E-mail:</label>
                    <input id="email" name="email" type="email" placeholder="ex: kingkaew@seafood.com" onChange={handleChange} value={reservation.email} required />
                    {error.email && <div style={{ color: 'red' }}>{error.email}</div>}
                    {reservationLinks.modifyLink && (
                        <p>
                            <a href={reservationLinks.modifyLink} target="_blank" rel="noopener noreferrer">Modify</a> |
                            <a href={reservationLinks.cancelLink} target="_blank" rel="noopener noreferrer"> Cancel</a>
                        </p>
                    )}
                </div>
                <div className="form-row">
                    <label htmlFor="phone">Phone:<span className="optional-text">(optional)</span></label>
                    <input id="phone" name="phone" type="tel" pattern="[0-9]*" onChange={handleChange} value={reservation.phone} />
                </div>
                <div className="form-row">
                    <label htmlFor="numberOfGuests"># of Guests:</label>
                    <input id="numberOfGuests" name="numberOfGuests" type="number" min="1" pattern="[0-9]*" onChange={handleChange} value={reservation.numberOfGuests} required />
                </div>
                <div className="form-row">
                    <label htmlFor="date">Date:</label>
                    <input
                        type="date"
                        id="date"
                        name="date"
                        value={reservation.date}
                        onChange={handleDateChange}
                        min={minDate}
                        required
                        ref={dateRef}
                    />
                    {dateError && <div style={{ color: 'red' }}>{dateError}</div>}
                </div>
                <div className="form-row">
                    <label>Time:</label>
                    <div>
                        {timeSlots.map((time, index) => (
                            <button
                                key={index}
                                type="button"
                                className={`time-button ${reservation.time === time ? 'selected' : ''} ${isWeekday() && time === '15:00' ? 'disabled' : ''}`}
                                onClick={() => { if (!(isWeekday() && time === '15:00')) handleTimeSelect(time); }}
                                disabled={isWeekday() && time === '15:00'}
                            >
                                {time}
                            </button>
                        ))}
                    </div>
                </div>

                <input type="submit" value="Reserve Table" />
                {loading && (
                    <div className="loading-spinner">
                        <dotlottie-player
                            src="https://lottie.host/7e326d25-ceba-45e4-996a-ecf989492080/EljaBZWVXc.json"
                            background="transparent"
                            speed="1"
                            style={{ width: 300, height: 300 }}
                            direction="1"
                            playMode="normal"
                            loop
                            autoplay
                        ></dotlottie-player>
                    </div>
                )}
                <a href="https://www.facebook.com/kingkaewseafood" target="_blank" rel="noopener noreferrer" className="facebook-follow">
                    Follow us on Facebook
                </a>
            </form>
        </div>
    );
}

export default ReservationForm;