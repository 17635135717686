import React from "react";
import { Link } from "react-router-dom";
import logo from "./logo.png";

function Header() {
    return (
        <header className="App-header">
            <Link to="/">
                <img src={logo} alt="Restaurant Logo" style={{ maxWidth: '600px' }} />
            </Link>
        </header>
    );
}

export default Header;